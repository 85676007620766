import "./styles.scss"

import React from "react"

const About = () => {
  return (
    <section className="investment-about">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <h2>O inwestycji</h2>
          </div>
          <div className="col-xl-5 col-md-6">
            <div className="investment-about__desc">
              <p>
                Mazowiecka 115 to znajdująca się w centrum Krakowa wygodna,
                nowoczesna przestrzeń, która dzięki przemyślanemu projektowi
                zapewnia wyjątkowy, sprzyjający odpoczynkowi klimat.
              </p>
              <p>
                Na 6 kondygnacjach mieści się 86, ustawnych mieszkań. Budynki
                oraz prywatny ogród tworzą kameralną, przyjazną atmosferę w
                najbardziej prestiżowej dzielnicy Krakowa.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="investment-about__info">
              <h5>
                86 <span>mieszkań</span>
              </h5>
              <h4>
                <span>Przestronne</span> apartamenty <span>dla wymagających</span> i{" "}
                <span>praktyczne</span> mieszkania dla każdego.
                Wszystkie stworzone z dbałością o najdrobniejsze szczegóły.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
