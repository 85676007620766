import "./styles.scss"

import React from "react"

const Desc = () => {
  return (
    <section className="investment-desc">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-7 col-xxl-6">
            <p>
              Mazowiecka 115 to nowoczesna inwestycja, oferująca nie tylko
              wyjątkowy komfort, ale także wygodę codziennego życia dzięki
              usługom dostępnym na parterze.
            </p>

            <div className="investment-desc__row">
              <div className="investment-desc__item">
                <div className="investment-desc__item-image">
                  <img
                    src={require("assets/icons/features/time.svg").default}
                    alt=""
                  />
                </div>
                <h4>Dla osób ceniących swój czas</h4>
              </div>
              <div className="investment-desc__item">
                <div className="investment-desc__item-image">
                  <img
                    src={require("assets/icons/features/activity.svg").default}
                    alt=""
                  />
                </div>
                <h4>Dla osób żyjących aktywnie</h4>
              </div>
              <div className="investment-desc__item">
                <div className="investment-desc__item-image">
                  <img
                    src={require("assets/icons/features/comfort.svg").default}
                    alt=""
                  />
                </div>
                <h4>Dla osób ceniących najwyższy komfort</h4>
              </div>
            </div>
          </div>

          <div className="col-lg-4 offset-lg-1 offset-xxl-2">
            <div className="investment-desc__logo">
              <img
                src={require("assets/images/logo-vertical.svg").default}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Desc
