import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Apartments = () => {
  return (
    <section className="investment-apartments">
      <div className="container-fluid">
        <h2>Mieszkania</h2>

        <div className="row">
          <div className="col-xl-4 col-lg-5">
            <p>Wybierz mieszkanie dopasowane do Twoich potrzeb.</p>
            <div className="investment-apartments__inner">
              <div className="row">
                <div className="col-6">
                  <strong>Ilość pokoi</strong>
                  <span>1-4</span>
                </div>

                <div className="col-6">
                  <strong>Piętra</strong>
                  <span>0-5</span>
                </div>

                <div className="col-12">
                  <strong>Metraże</strong>
                  <span>
                    26 m<sup>2</sup> - 75 m<sup>2</sup>
                  </span>
                </div>
              </div>
              <Button to="/mieszkania/">zobacz</Button>
            </div>
          </div>

          <div className="col-xl-7 offset-xl-1 col-lg-7">
            <img
              src={
                require("assets/images/home-apartments-visualisation.png")
                  .default
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Apartments
