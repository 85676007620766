import "./styles.scss"

import React from "react"

const Convenience = () => {
  return (
    <section className="investment-convenience">
      <div className="container-fluid">
        <div className="investment-convenience__wrapper">
          <h2>Udogodnienia</h2>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="investment-convenience__item">
                <div className="investment-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-1.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Wygodna lokalizacja w sercu Krakowa</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="investment-convenience__item">
                <div className="investment-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-2.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Nowoczesna i praktyczna architektura</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="investment-convenience__item">
                <div className="investment-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-3.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Zabytkowy sznyt w centrum miasta</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="investment-convenience__item">
                <div className="investment-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-4.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Balkony, tarasy i ogrody</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="investment-convenience__item">
                <div className="investment-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-5.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>
                  Prywatny wspólny ogród oraz liczne tereny zielone w okolicy
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="investment-convenience__item">
                <div className="investment-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-6.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Dobrze doświetlone, ustawne mieszkania</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="investment-convenience__item">
                <div className="investment-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-7.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Najważniejsze usługi i sklepy na parterze</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="investment-convenience__item">
                <div className="investment-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-8.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Duży potencjał aranżacyjny i możliwość łączenia mieszkań</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Convenience
