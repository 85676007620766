import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import {
  Desc,
  About,
  Convenience,
  Apartments,
  Location,
  Image,
} from "page_components/investment"

const Investment = () => (
  <Layout seo={{ title: "O inwestycji" }}>
    <PageHeader title="O inwestycji" />
    <Desc />
    <About />
    <Convenience />
    <Apartments />
    <Location />
    <Image />
  </Layout>
)

export default Investment
