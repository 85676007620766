import "./styles.scss"

import React from "react"

import Map from "components/Map"
import Button from "components/Button"

const Location = () => {
  return (
    <section className="investment-location">
      <div className="row g-0">
        <div className="col-xl-9">
          <Map />
        </div>
      </div>

      <div className="investment-location__wrapper">
        <div>
          <h2>Lokalizacja</h2>
          <h4>ul. Mazowiecka 115</h4>
          <p>
            Najlepszy wybór dla ceniących wygodę i bliskość tętniącego życiem
            centrum Krakowa. Mazowiecka 115 to lokalizacja idealna dla osób,
            którym zależy na sprawnym poruszaniu się po mieście i łatwym
            dostępie do oferty rozrywkowej, usługowej i rekreacyjnej miasta.
          </p>
        </div>
        <Button to="/lokalizacja/">zobacz</Button>
      </div>
    </section>
  )
}

export default Location
