import "./styles.scss"

import React from "react"

const Image = () => {
  return (
    <section className="investment-image">
      <div className="container-fluid">
        <img
          src={require("assets/images/home-image-main.jpg").default}
          alt=""
        />
      </div>
    </section>
  )
}

export default Image
